import styled from "styled-components"

const Triangle = styled.span`
  width: 0;
  height: 0;
  border-top: 1282px solid transparent;
  border-right: 1345px solid ${props => (props.color ? props.color : "#a7dbc7")};
  border-bottom: 1282px solid transparent;
  position: absolute;
  right: 0;
  top: -82px;
  z-index: -2;
  @media (max-width: 568px) {
    border-top-width: 400px;
    border-bottom-width: 400px;
    border-right-width: 400px;
    top: 50px;
  }
`
export default Triangle
